<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div :class="`delete-modal ${size}`">
        <div>
          <h1 class="text-base heading">
            {{(ACTION == 'APPOINTMENT' && canBeDeleted) || ACTION !== 'APPOINTMENT' ? 'Are you sure you want to delete the selected item ?' : 'You can\'nt delete a completed appointment'}}
          </h1>
          <div class="flex" :class="{'justify-center' : !((ACTION == 'APPOINTMENT' && canBeDeleted) || ACTION !== 'APPOINTMENT')}">
            <div>
              <button
                :style="`backgroundColor: ${abort}`"
                class="
                  rounded
                  w-40
                  mt-8
                  ml-8
                  text-base
                  outline-none
                  font-extralight
                "
                @click="close()"
              >
                {{(ACTION == 'APPOINTMENT' && canBeDeleted) || ACTION !== 'APPOINTMENT' ? 'Abort' : 'Cancel'}}
              </button>
            </div>
            <div v-if="(ACTION == 'APPOINTMENT' && canBeDeleted) || ACTION !== 'APPOINTMENT'">
              <button
                :style="`backgroundColor:${deleteBg} `"
                class="
                  rounded
                  w-40
                  mt-8
                  ml-8
                  text-base
                  outline-none
                  font-extralight
                "
                @click="deleteFunc(id)"
              >
                Delete
              </button>
            </div>
            <!-- <ActionButton color="#B6C0C9" name="Abort" @click="close()" /> -->
            <!-- <ActionButton -->
            <!-- color="#28A4E2" -->
            <!-- name="Delete" -->
            <!-- @click="deleteFunc(id)" -->
            <!-- /> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import ActionButton from "/src/components/Listings/ActionButton.vue";
import Api from "/src/services/Apis";
// import Notification from "/src/components/Notification.vue";
export default {
  name: "DeleteModal",
  components: {
    // ActionButton,
    // Notification,
  },
  data() {
    return {
      msg: "",
      abort: "#B6C0C9",
      deleteBg: "#28A4E2",
    };
  },
  props: {
    size: {
      type: String,
      default: "big",
    },
    id: {
      type: Array,
    },
    canBeDeleted: {
      type: Boolean,
      default: true,
    },
    ACTION: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async deleteFunc(id) {
      if (this.ACTION === "APPOINTMENT") {
        await Api.delete("appointment/" + id)
          .then((resp) => {
            this.msg = resp.data.message;
            this.$emit("deleted");
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.ACTION === "USER") {
        await Api.delete("users/" + id)
          .then((resp) => {
            this.msg = resp.data.message;
            this.$emit("deleted");
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.ACTION === "MINISTRY") {
        await Api.delete("institutions/" + id)
          .then((resp) => {
            this.msg = resp.data.message;
            this.$emit("deleted");
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.ACTION === "CLUSTER") {
        await Api.delete("clusters/" + id)
          .then((resp) => {
            this.msg = resp.data.message;
            this.$emit("deleted");
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.ACTION === "DOCUMENT") {
        await Api.delete("documents/" + id)
          .then((resp) => {
            this.msg = resp.data.message;
            this.$emit("deleted");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$emit("deleted");
      }
    },
    closeNotification() {
      this.msg = "";
      this.hasError = false;
      this.submsg = "";
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(33, 33, 33, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
button {
  height: 3rem;
  color: white;
}

.delete-modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  position: absolute;
  overflow-y: auto;
  margin: auto;
  flex-direction: column;
  padding: 24px;
  border-radius: 6px;
  width: 90%;
  &.big {
    // max-width: 1039px;
    // min-height: 607px;
    max-width: 500px;
    min-height: 160px;
    .close {
      position: absolute;
      right: 37px;
      top: 25px;
    }
  }
  &.small {
    .close {
      display: none;
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.heading {
  text-align: center;
  font-size: 1.1rem;
  color: #032b3d;
  font-weight: 600;
}
</style>
