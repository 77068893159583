<template>
  <div
    class="flex w-56 create bg-create mt-7 rounded cursor-pointer bg-black"
    v-if="isSecretary"
    @click="$emit('click')"
  >
    <img
      v-if="icon === 'xlsx'"
      :src="require(`/public/icons/${icon}.png`)"
      alt=""
      style="height: 30px; margin-top: 12px"
      class="ml-3.5"
    />
    <img
      v-else
      :src="require(`/public/icons/${icon}.svg`)"
      alt=""
      class="w-6 ml-3.5"
    />
    <router-link class="text-center pt-4 pl-4 text-white paraph" :to="route">{{
      title
    }}</router-link>
  </div>
</template>
<script>
export default {
  name: "Button",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    isSecretary: {
      type: Boolean,
    },
    route: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.create {
  min-height: 3.3rem !important;
}
.paraph {
  color: white;
  box-shadow: 0px 4px 4px rgba(22, 121, 168, 0.25);
}
</style>
