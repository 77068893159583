l
<template>
  <div class="ml-8">
    <div class="flex justify-between">
      <div>
        <h1 class="appointments text-justify text-xl mt-12 text-appointments">
          System Clusters
        </h1>
        <Button
          title="Add Cluster"
          icon="create-user"
          :isSecretary="true"
          route="/cluster"
          v-if="$store.state.auth.user.roles[0].name === 'ADMIN'"
        />
      </div>
      <div class="mt-40 md:mt-32 block mr-10">
        <Search
          label="Search by name"
          class="w-50 -ml-12"
          @input="searchCluster"
        />
      </div>
    </div>
    <div
      class="flex pl-3 md:hidden"
      @change="generalCheck($event)"
      v-if="$store.state.auth.user.roles[0].name === 'ADMIN'"
    >
      <h1 class="pl-2 text-xs">Select clusters</h1>
      <div class="mt-12 -ml-20">
        <AppointmentActionButtons
          @deleted="removedDeletedItems"
          v-if="selectedClusters.length"
          :isSecretary="true"
          :showIcon="true"
          :deleteFill="`${selectedClusters.length ? '#FB2222' : '#B0B0B0'}`"
          :id="selectedClusters"
          ACTION="CLUSTER"
        />
      </div>
    </div>
    <div v-if="elementsAvailble">
      <table>
        <thead>
          <tr
            class="flex flex-col flex-no wrap table-row rounded-l-lg rounded-none mb-2 mb-0"
          >
            <th class="p-3 text-left invisible">
              <input type="checkbox" @change="generalCheck($event)" />
            </th>
            <th v-if="fields['title']" class="p-3 text-left">
              <span class="flex text-table text-xs">Cluster</span>
            </th>
            <!-- <th class="p-3 text-left">
              <span class="flex sm:flex text-table text-xs "
                >Total Users<img
                  src="/icons/arrow.svg"
                  alt=""
                  class="sm:w-4 sm:block hidden "
              /></span>
            </th> -->
            <th v-if="fields['status']" class="p-3 text-left">
              <span class="flex text-table text-xs">Status</span>
            </th>
            <th v-if="fields['institution']" class="p-3 text-left">
              <span class="flex text-table text-xs">Institution</span>
            </th>
            <th v-if="fields['dateAndTime']" class="p-3 text-left">
              <span class="flex text-table text-xs">Date added</span>
            </th>

            <th class="p-3 text-left text-table text-xs flex justify-between">
              <p class="md:-ml-6">Actions</p>
              <AppointmentActionButtons
                @deleted="removedDeletedItems"
                v-if="selectedClusters.length"
                :isSecretary="true"
                :showIcon="true"
                :deleteFill="`${
                  selectedClusters.length ? '#FB2222' : '#B0B0B0'
                }`"
                :id="selectedClusters"
                ACTION="CLUSTER"
              />
            </th>
          </tr>
        </thead>
        <tbody class="sm:flex-1 sm:flex-none">
          <tr
            v-for="cluster in clusters"
            :key="cluster.id"
            class="sm:flex sm:flex-col sm:flex-no sm:wrap sm:table-row sm:mb-2 sm:mb-0 h-80 main-header sm:header sm:shadow-lg"
          >
            <td class="sm:p-3 text-left">
              <input type="checkbox" @change="onChange(cluster.id)" />
            </td>
            <td v-if="fields['title']" class="sm:p-3 flex text-left">
              <p
                class="text-xs -ml-2 mt-4 md:mt-2 sm:text-sm sm:text-values title"
              >
                {{ cluster.name }}
              </p>
            </td>
            <!-- <td class="p-3">
              <span
                class="-ml-7 lg:-ml-24 sm:text-values text-xs sm:text-sm sm:title"
              >
                {{ cluster.totalUsers }}
              </span>
            </td> -->
            <td v-if="fields['status']" class="p-3 sm:font-semibold">
              <div class="w-24 sm:w-24 sm:h-4 sm:px-0.1 sm:py-0.1 bg-white">
                <span class="sm:text-values text-xs sm:text-sm sm:title">{{
                  cluster.status
                }}</span>
              </div>
            </td>
            <td v-if="fields['institution']" class="p-3 text-left">
              <span class="sm:text-values text-xs sm:text-sm text-justify">{{
                cluster.institutions.length
                  ? cluster.institutions.length
                  : "Not yet attached"
              }}</span>
            </td>
            <td v-if="fields['dateAndTime']" class="p-3 text-left">
              <span class="sm:text-values text-xs sm:text-sm">{{
                cluster.createdAt
              }}</span>
            </td>

            <td class="text-values text-sm p-3 flex">
              <div
                class="w-24 rounded text-view cursor-pointer h-7 bg-table p-0.5 pt-1 pb-1 text-center"
                @click="editCluster(cluster)"
              >
                <span class="view">Edit</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailble"
      :isLoading="loading"
      type="Cluster"
    />
  </div>
</template>
<script>
import Button from "/src/components/Listings/Button.vue";
import AppointmentActionButtons from "/src/components/Listings/AppointmentActionButtons.vue";
import Search from "/src/components/searchInput.vue";
import Api from "/src/services/Apis";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import { mapActions } from "vuex";

export default {
  name: "ClusterListing",
  components: {
    Button,
    AppointmentActionButtons,
    Search,
    ElementsNotFound,
  },
  data() {
    return {
      numberOfChecks: 0,
      checkDisabled: false,
      institutions: [],
      institutionName: [],
      date: [],
      loading: true,
      elementsAvailble: true,
      clusters: [],
      selectedClusters: [],
      fields: [],
    };
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    removedDeletedItems() {
      this.clusters = this.clusters.filter(
        (e) => e.id != this.selectedClusters[0]
      );
      this.selectedClusters = [];
      this.$store.dispatch("notification/SET_NOTIFICATION", {
        msg: "Cluster deleted sucessfully",
        uptime: 3000,
      });
    },
    onChange(id) {
      this.selectedClusters.push(id);
    },
    generalCheck(e) {
      if (e.target.checked) {
        this.numberOfChecks++;
      } else {
        this.numberOfChecks--;
      }
      if (this.numberOfChecks > 0) {
        for (let cluster of this.clusters) {
          this.selectedClusters.push(cluster.id);
        }
      } else {
        this.selectedClusters = [];
      }
    },

    async getClusters() {
      await Api.get("clusters")
        .then((response) => {
          this.clusters = response.data.content;
          if (!this.clusters.length) {
            this.elementsAvailble = false;
          } else {
            this.elementsAvailble = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async searchCluster(clusterName) {
      if (clusterName === "") {
        this.getClusters();
      } else {
        await Api.get("clusters/search/?name=" + clusterName)
          .then((resp) => {
            this.clusters = resp.data;
            if (!this.clusters.length) {
              this.elementsAvailble = false;
            } else {
              this.elementsAvailble = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editCluster(cluster) {
      this.$store.commit("admin/setSelectedCluster", cluster);
      this.$router.push(`/update-cluster/${cluster.id}`);
    },
    // async searchCluster(name){
    //       if (typeof name === "object") {
    //     await Api.get("clusters/search/?name=" + name.target.value)
    //       .then((resp) => {

    //         this.clusters = resp.data;
    //        if (!this.clusters.length) {
    //         this.elementsAvailble = false;
    //       }else{
    //         this.elementsAvailble = true
    //       }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // }
  },
  computed: {
    // disableBtn() {
    //   if (this.selectedClusters.length) {
    //     return (this.checkDisabled = false);
    //   } else if (this.selectedClusters.length) {
    //     return (this.checkDisabled = true);
    //   }
    // },
  },
  async created() {
    this.fields = await this.getFields("clusters_table");
    this.getClusters();
  },
};
</script>
<style scoped>
table {
  width: 97%;
  border-collapse: collapse;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.title {
  font-weight: 600;
}
.header {
  background: white !important;
  height: 3.5rem;
}
.appointments {
  font-weight: 600;
  font-size: 20px !important;
}

.names {
  margin: 0 3rem 0 -8rem;
}

@media only screen and (max-width: 760px),
  (min-device-width: 250px) and (max-device-width: 640px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    background: white;
    width: 20rem;
    margin: 1rem 0 0 0;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%);
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    text-align: justify;
    color: #000000;
  }

  td:nth-of-type(2):before {
    content: "Cluster";
  }
  td:nth-of-type(3):before {
    content: "Status";
  }
  td:nth-of-type(4):before {
    content: "Institution";
  }
  td:nth-of-type(5):before {
    content: "Date Added";
  }
  td:nth-of-type(6):before {
    content: "Actions";
  }
  td:nth-of-type(7)::before {
    content: "Action";
  }
}
</style>
